<template>
	<div>ВСЕ АКЦИИ</div>
</template>

<script>
	export default {
		name: 'ShowSales',
	}
</script>

<style scoped>

</style>